* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

/* Fix cron dropdown to show on top of mui modal */
.ant-select-dropdown {
    z-index: 1301;
}

/** REACT CHECKBOX TREE */
.rct-title {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.rct-text label {
    display: flex;
    align-items: center;
}

.reportClass {
    flex: 1;
}

.hidden {
    display: none;
}